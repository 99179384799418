
@font-face {
    font-family:'skr';
    src: url(./fonts/Poppins-Regular.ttf);
}
@font-face {
    font-family:'skb' ;
    src: url(./fonts/Poppins-Bold.ttf);
}



:root{
    --yellow-color: #FDCB4A;
    --light-black-color:#C4C4C4 ;
    --white-color: #FFFFFF;
    --black-color: #171717;
    
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    /* overflow-x: hidden; */
    
}

a { color: inherit; } 

html {
  scroll-behavior: smooth;
}




textarea::placeholder {font-family:'skr';; color: #333;}
input::placeholder {font-family:'skr';; color: #333;}

*::selection {
  background: #131313;
  color: #FDCB4A;
}



body{
    /* background-color: #F3F3F3; */
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    font-family: skr;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #4d7fff #ddd;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #FDCB4A;
  border-radius: 5px;
}

body::-webkit-scrollbar-track {
  /* background-color: #ddd;
  border: 1px solid #ccc; */
}

input:focus{ border: none; }

      .close-popUp{
          /* display: none; */
          transform: translateY(-900px);
      }



